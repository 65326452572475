#aboutMe {
    width: 100%;
    margin-bottom: 4%;
    padding-top: 1%;
    min-height: 100vh;
    background-color: var(--bgColor);
    color: var(--textColor);
    font-family: var(--fontFamily);
    font-size: 20px;
}

#aboutMe #skills{
    padding-top: 3%;
}

#aboutMe p {
    letter-spacing: 1px;
    font-size: 16px;
    line-height: 2;
    font-weight: 400;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

#aboutMe ol{
    margin-top: 9px;
}

/* #aboutMe .bio{
    margin-left: 50px;
} */

/* #aboutMe .iconBlock{
    
    position: relative;
    display:inline-block;
    margin-left: -40px;

} */

#aboutMe .icon{
    width:30px;
    height:30px;
    display: inline-block;
    /* vertical-align: -1.7em; */
    /* display:inline-block; */
}

#aboutMe #name{
    color: var(--highlightColor)
}

#aboutMe #role-name{
    color: var(--highlightColor)
}

#aboutMe .list{
    display: flex;
    margin:10px 0px;
}

#aboutMe .title{
    min-width: 90px;
    display: inline;
    margin-right: 20px;
    font-weight: 500;
}

#aboutMe .value{
    display: inline-block;
    margin-right: 20px;
}

#aboutMe .heading{
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 20px;
    text-transform: uppercase;
    line-height: 1.4;
}

#aboutMe li{
    margin: 0;
	padding: 10px 0 10px 30px;
	list-style: none;
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 22px;
}

/* #aboutMe .aboutMemainContainer{
    padding-top: 2%;
} */

/* #aboutMe .descBlock{
    padding-top: 2%;
} */
/* #aboutMe .containerBlock{
    padding:100px;
    
} */

#aboutMe .skillName{
    padding-top:10%;
    font-size:15px;
}

#aboutMe .skillIcons:hover .skillName{
   color:var(--highlightColor);
  }

#aboutMe .imageBlock{
    
    text-align: center;
    border-radius: 50%;
    
    padding: 10px;
    /* animation: spin 5s infinite linear; */
}

#aboutMe .imageBlock img {
  max-width: 100%;
  height: auto;
}

#aboutMe .myphoto{
    height:auto ;
    
    width: 100%;
    /* margin-top: 10%; */
    /* border: 0.1px solid  gray!important; */
    border-radius: 50%; 
    filter: blur(0.3px);
    
    -webkit-filter: blur(0.3px);
    /* animation: spin 5s infinite reverse linear; */
}



#aboutMe .iconImage{
    filter: grayscale(1); /* IE5+ */
    -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
    -o-filter: grayscale(1); 
    -moz-filter: grayscale(1);
    -ms-filter: grayscale(1);
/* 
    -webkit-transition: all .1s ease-in-out; 
    -o-transition: all .1s ease-in-out; 
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out; */

    transition: all .1s ease-in-out;
    -webkit-transition: all .1s ease-in-out; 
    -o-transition: all .1s ease-in-out; 
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;
}

#aboutMe .iconImage:hover {
    filter: none;
    -webkit-filter: grayscale(0); /* Webkit Nightlies & Chrome Canary */
    -o-filter: grayscale(0); 
    -moz-filter: grayscale(0);
    -ms-filter: grayscale(0);
    transform: scale(1.08);
    -webkit-transform: scale(1.08);
    -o-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -ms-transform: scale(1.08);
}

#aboutMe .skillImage{
    height: 70px;
    width: 70px;
    display: inline;
    /* background-color: #333; */
    
}

#aboutMe  .skillHeading{
    color: var(--textColor);
    text-align: center;
    padding-bottom: 20px;
    text-transform: uppercase;
}

#aboutMe .highlightText{
    color: var(--highlightColor);
}

#aboutMe .skillBlock{
    justify-content: center;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

#aboutMe .skillIcons{
    margin: 10px!important;
    
    padding: 10px!important;
    /* opacity: .8!important; */
    border: 0.1px solid  gray!important;
    vertical-align: middle!important;
    text-align: center!important;
    border-radius: 5px!important;
    display: table!important;
    /* box-shadow: 4px 5px 4px 3px rgba(89,4,168,.137)!important; */
    overflow: hidden!important;
    /* transition: all .4s ease 0s!important; */

    /* filter: grayscale(1); 
    -webkit-filter: grayscale(1); 
    -o-filter: grayscale(1); 
    -moz-filter: grayscale(1);
    -ms-filter: grayscale(1); */

    transition: all .1s ease-in-out;
    -webkit-transition: all .1s ease-in-out; 
    -o-transition: all .1s ease-in-out; 
    -moz-transition: all .1s ease-in-out;
    -ms-transition: all .1s ease-in-out;

}


#aboutMe .skillIcons:hover {
    filter: none;
    -webkit-filter: grayscale(0); /* Webkit Nightlies & Chrome Canary */
    -o-filter: grayscale(0); 
    -moz-filter: grayscale(0);
    -ms-filter: grayscale(0);
    transform: scale(1.08);
    -webkit-transform: scale(1.08);
    -o-transform: scale(1.08);
    -moz-transform: scale(1.08);
    -ms-transform: scale(1.08);
}


@media (max-width: 768px) {
    #aboutMe .descBlock{
        padding-top: 10%;
    }
}