.sidebarList {
  height: auto;
  width: auto;
  padding-left: 29%;
  position: absolute;
  top: 25%;
  padding-top: 15%
  /* bottom:20%; */
  /* border-right: #111111 1px solid; */
}


#profileName {
  text-align: center;
  color: #ffffff;
  margin: 10% 0 10% 0;
  letter-spacing: 0.3px;
  font-weight: bold;
  font-size: 35px;
  text-decoration: none;
  cursor: pointer;
  padding-bottom: 20px;
}

.media{
  vertical-align: middle;
}


.sidebarList .row {
  margin: 0;
  
  padding: 10px 0 10px 30px;
  list-style: none;
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 25px;
  list-style-type: none;
  text-decoration:none;
  margin: 0;
  flex-direction: row;
  justify-content: left;
  align-items: left;
  margin: 0px 0 10px 0;
  display: flex;
  cursor: pointer;  
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  filter: grayscale(1); /* IE5+ */
  -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
  -o-filter: grayscale(1); 
  -moz-filter: grayscale(1);
  -ms-filter: grayscale(1);
}


.sidebarList .nav-title {
  flex: 70%;
  text-decoration: solid;
  /* font-weight: normal; */
  font-style: normal;
  font-variant-ligatures: normal;
  font-variant-caps: normal;
  font-variant-numeric: normal;
  font-variant-east-asian: normal;
  font-stretch: normal;
  line-height: 15.5px;
  font-family: var(--navBarFont);
  letter-spacing: 1.5px;
  display: inline;
  place-items: center;
  padding-left: 20px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  color: var(--textColor);
  font-size: 15px;
}

.sidebarList .row:hover {
  cursor: pointer;
  /* font-weight: bold; */
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  filter: none;
  -webkit-filter: grayscale(0); /* Webkit Nightlies & Chrome Canary */
  -o-filter: grayscale(0); 
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  color: var(--highlightColor);
  transform: scale(1.09);
  -webkit-transform: scale(1.09);
  -o-transform: scale(1.09);
  -moz-transform: scale(1.09);
  -ms-transform: scale(1.09);
}

.sidebarList .row:hover .nav-title {
  font-weight: bold;
  color: var(--highlightColor);
}

.sidebarList .active li{
  text-decoration: solid;
  /* font-weight: bold; */
  filter: none;
  -webkit-filter: grayscale(0); /* Webkit Nightlies & Chrome Canary */
  -o-filter: grayscale(0); 
  -moz-filter: grayscale(0);
  -ms-filter: grayscale(0);
  color: var(--highlightColor);
  transform: scale(1.09);
  -webkit-transform: scale(1.09);
  -o-transform: scale(1.09);
  -moz-transform: scale(1.09);
  -ms-transform: scale(1.09);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.sidebarList .active .nav-title{
  font-size: 15.5px;
  color: var(--highlightColor);
  font-weight: bold;
 
}

.MuiDrawer-paper {
  z-index: 0;
}
