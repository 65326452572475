html {
  /* --textColor: #F4F4EA; */
--textColor:#dee0e4;
  /* --textColor: #E3E0F3; */
  --bgColor: #000000;
  /* --bgColor:#171c28; */
  --highlightColor: #7dba5c;
  --sectionBgColor: #1a1a1a;
  --shadeColor: #aeaeae;
  --overlayColor:#7dba5cCC;
  /* --navbarColor:#131B25; */
  --navbarColor:#000000;
  --dividerColor:#333333;
  --whiteColor:#ffffff;
  --fontFamily: "Calibre","San Francisco","SF Pro Text",-apple-system,system-ui,sans-serif;
  --navBarFont:Montserrat, Montserrat, sans-serif;
}
/* 
#root::-webkit-scrollbar {
  display: none;
} */

.modal-backdrop {
    background-color:black !important; /* Change the color and opacity as needed */
}

/* Hide scrollbar for IE, Edge and Firefox */

.textFont{
  font-size: 1rem;
}


.headingFont{
  font-size: 3rem;
}

.defaultMargin{
  margin-left: 3.5%; 
    margin-right: 2%; 
}

body {
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #1c2915;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #405f30;
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7dba5c;
  border-radius: 12px;
}

.content::-webkit-scrollbar {
  width: 0 !important
}

.content {
  overflow: -moz-scrollbars-none;
}


.content{
  background-color:var(--bgColor);
  /* overflow-y: scroll; */
}

.headerUnderline {
  width: 100px;
  height: 6px;
  background-color: var(--highlightColor);
  margin: 20px 0 20px 0;
}

.smallHeaderUnderline {
  width: 100px;
  height: 4px;
  background-color: var(--highlightColor);
  margin: 20px 0 20px 0;
}

.centerUnderline{
  align-content: center;
}

/* .headerUnderline:after {
  content: '';
  position: absolute;
  width: 75px;
  height: 6px;
  background-color: var(--highlightColor);
  /* background: yellow; 
} */



/* .headerUnderline:before {
  content: '';
  position: absolute;
  width: 40%;
  left: 60%;
  height: 6px;
  background: green;
} */



.contentDivider {

  width: auto;
  height: 1px;
  background-color: var(--dividerColor);
 
  /* margin: 50px 0 0 0; */
  clear: both;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 400;
  font-family: var(--fontFamily);
  color: var(--textColor);
  /* text-transform: uppercase; */
}

.margin-bottom50 {
  margin-bottom: 50px;
}

.margin-bottom40 {
  margin-bottom: 40px;
}

.margin-bottom30 {
  margin-bottom: 30px;
}

.margin-bottom20 {
  margin-bottom: 20px;
}

.margin-bottom10 {
  margin-bottom: 10px;
}

.margin-top50 {
  margin-top: 50px;
}

.margin-top40 {
  margin-top: 40px;
}

.margin-top30 {
  margin-top: 30px;
}

.margin-top20 {
  margin-top: 20px;
}

.margin-top10 {
  margin-top: 10px;
}

.no-border-bottom {
  border-bottom: none !important;
}

.margin-right30 {
  margin-right: 30px;
}

@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,600;0,700;0,800;0,900;1,400&display=swap");


.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 540px
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px
}
