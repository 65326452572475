#certificates {
    width: 100%;
    padding-top: 1%;
    /* margin-bottom: 4%; */
    color: var(--textColor);
    min-height: 100vh;
    background-color: var(--bgColor);
    font-size: 20px;
}

#certificates .card {
    border: 1px solid var(--dividerColor);
    background-color: transparent;
    min-height: 220px;
    max-height: 220px;
  }

  #certificates .card-course-title-div{
    min-height: 80px;
    max-height: 80px;
  }

  #certificates .card-course-title{
    text-align: center;
    vertical-align:middle;
    display:flex;
    align-items: center;
    justify-content: center;
  }

  #certificates .certificate-info{
    text-align: center;
  }

  #certificates .card-image-logo-div{
    border-radius: 10px;
    text-align: center;
  }

  #certificates .card-image-logo{
      height: 120px;
      width: 120px;
  }
  
  #certificates .certificate {
    background-color: var(--bgColor);
    color: var(--textColor);
  }
  
  #certificates .certificate-overlay {
    background: var(--overlayColor);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    /* padding-left: 10px; */
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
  }
  
  #certificates .certificate:hover .certificate-overlay {
    opacity: 0.5;
  }
  
  #certificates .certificate:hover .mask-btn {
    box-shadow: none;
  }
  
  #certificates .certificate-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }
  
  #certificates .certificate:hover .certificate-details {
    top: 50%;
    left: 50%;
    opacity: 1;
  }
  
  #certificates .certificate:hover .card {
    opacity: 0.7;
  }
  
  #certificates .fadeIn-bottom {
    top: 80%;
  }
  
  #certificates .mask-btn,
  #certificates .mask-btn:hover,
  #certificates .mask-btn:active {
    border-color: none;
    box-shadow: none;
  }
  
#certificates  .popup {
    /* margin: 0 auto; */
    /* margin-top: 5%; */
    vertical-align: middle;
  }
  
  #certificates .vertical-align {
    margin: auto;
  }
  
  #certificates .certificate-content {
    margin-top: 30px;
  }
/*   
  #certificates #projectImage {
    width: 80px;
    height: 80px;
  } */
  
  #certificates .popup-close {
    width: 34px;
    height: 34px;
    padding-top: 4px;
    display: inline-block;
    position: absolute;
    top: 20px;
    right: 20px;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    border-radius: 100%;
    background: transparent;
    border: solid 4px #808080;
  }
  
  #certificates .popup-close:after,
  #certificates .popup-close:before {
    content: "";
    position: absolute;
    top: 11px;
    left: 5px;
    height: 4px;
    width: 16px;
    border-radius: 30px;
    background: #808080;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  
    #certificates .popup-close:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  
  #certificates .popup-close:hover {
    -webkit-transform: translate(50%, -50%) rotate(180deg);
    transform: translate(50%, -50%) rotate(180deg);
    background: #f00;
    text-decoration: none;
    border-color: #f00;
  }
  
  #certificates .popup-close:hover:after,
  #certificates .popup-close:hover:before {
    background: #fff;
  }
  
  #certificates .btn:focus,
  #certificates .btn:active {
    outline: none !important;
    box-shadow: none;
  }

 #certificates .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1100; /* Above the backdrop */
    background: rgba(0, 0, 0, 0.9);
    padding: 20px;
    border-radius: 8px;
}

 #certificates .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent black */
    z-index: 1000; /* Below the modal */
}
  
  #certificates .modal-content {
    background-color: var(--navbarColor);
  }
  
  #certificates .modal-header {
    border: none;
  }
  
  /* Extra small devices (phones, 600px and down) */
  @media only screen and (max-width: 600px) {
    .popup {
      /* margin-top: 12%; */
    }
  
    .projectsMenu {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  
    .projectsContent {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  
    #projectImage {
      height: 100px;
      width: 100px;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    .popup {
      /* margin-top: 9%; */
    }
    #projectImage {
      height: 60px;
      width: 60px;
    }
  }
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .popup {
      /* margin-top: 5%; */
    }
    #projectImage {
      height: 80px;
      width: 80px;
    }
  }
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .popup {
      /* margin-top: 4%; */
    }
  
    #projectImage {
      height: 100px;
      width: 100px;
    }
  }
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    .popup {
      /* margin-top: 2%; */
    }
  
    #projectImage {
      align-self: center;
      height: 200px;
      width: 200px;
    }
  }
  