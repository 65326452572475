#experience {
  /* position: relative; */
  /* padding: 20px; */
  margin-bottom: 4%;
  width: 100%;
  padding-top: 1%;
  min-height: 100vh;
  color: var(--textColor);
  background-color: var(--bgColor);
  font-size: 16px;
  
  /* background-color: var(--bgColor); */
  /* width: 100vw; */
}

#experience .offset-md-1 .offset-sm-1 .offset-lg-1 .offset-xl-1 {
  margin-left: 5%;
  margin-right: 5%;
}



/* .inner p {
  position: relative;
  padding: 0;
} */

.flot-left {
  float: left;
}
.flot-right {
  float: right;
}

/* .gray-bg {
  background-color: #1a1a1a;
}
.white-bg {
  background-color: #000000;
} */

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
  float: left;
  position: relative;
}

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}


.experienceMainContainer .icon-block i {
  font-size: 60px;
  color: #7dba5c;
  margin: 0px 0 0;
}
.experienceMainContainer h5 {
  margin: 5px 0 7px 0;
}
.experienceMainContainer span {
  font-size: 16px;
}
.experience-details {
  float: left;
}
.media img {
  width: 70px;
  height: 70px;
  /* overflow: "hidden"; */
  border-radius: 50%;
  vertical-align: middle;
  float: left;
  padding-left: 0px;
  margin-left: 0px;
  padding-right: 0px;
}

#experience .skills-block {
  margin-bottom: 2px;
  margin-top: 5px;
}

#experience .content-skills {
  font-size: 14px;
  padding: 0;
  margin-bottom: 0px;
  margin: 0px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

#experience .content-skills li {
  background: #40484d;
  border-radius: 2px;
  display: inline-block;
  padding: 2px 10px;
  color: rgba(255, 255, 255, 0.7);
  margin: 1px 1px;
  text-align: center;
  flex-grow: 1;
}