#education_achievement{
width: 100%;
margin-bottom: 4%;
min-height: 100vh;
background-color: var(--bgColor);
color: var(--textColor);
padding-top: 1%;
font-size: 20px;
}

#education .media{
    margin-top:0;
}

.detailsRow{
  padding-top: 5px;
}

#achievements{
  padding-top: 3%;
}