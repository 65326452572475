#home {
  min-height: 100vh;
  background-color: var(--bgColor);
  color: var(--textColor);
  font-size: 20px;
  font-family: var(--fontFamily);
}

#home .content-wrapper {
  max-width: 1300px;
  margin: 0 auto;
  padding: 2rem;
  width: 100%;
}

#home .hey-section {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

#home .heyImage {
  width: 150px;
  height: 75px;
}

#home .name {
  font-size: 70px;
  color: #bad0f7;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 1.1;
  font-family: Montserrat, "Montserrat", sans-serif;
  margin: 0;
}

#home .description {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

#home .social-media-div {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

#home .icon-button span {
  color: white;
  border-radius: 2.6rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  height: 50px;
  width: 50px;
  margin: 0 5px;
  transition: all 0.3s ease;
}

#home .icon-button:hover span {
  background-color: #ffffff;
}

#home .icon-button:hover .icon {
  color: #000000;
}

#home .wave {
  animation-name: wave-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  transform-origin: 70% 70%;
  display: inline-block;
  font-size: 50px;
}

@keyframes wave-animation {
  0% { transform: rotate(0.0deg) }
  10% { transform: rotate(14.0deg) }
  20% { transform: rotate(-8.0deg) }
  30% { transform: rotate(14.0deg) }
  40% { transform: rotate(-4.0deg) }
  50% { transform: rotate(10.0deg) }
  60% { transform: rotate(0.0deg) }
  100% { transform: rotate(0.0deg) }
}

#home .resumeBtn {
  background-color: var(--textColor);
  color: var(--bgColor);
  padding: 0.5rem 2rem;
  transition: all 0.3s ease;
}

#home .resumeBtn:hover {
  background-color: var(--highlightColor);
  color: var(--whiteColor);
}

#home .resumeLink {
  text-decoration: none;
}

@media (min-width: 1400px) {
  #home .content-wrapper {
    max-width: 1400px;
  }
  #home .name {
    font-size: 80px;
  }
  #home .description {
    font-size: 1.4rem;
    max-width: 1000px;
  }
}

@media (min-width: 992px) and (max-width: 1399px) {
  #home .content-wrapper {
    max-width: 1000px;
  }
  #home .name {
    font-size: 70px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #home .content-wrapper {
    max-width: 800px;
  }
  #home .name {
    font-size: 60px;
  }
}

@media (max-width: 767px) {
  #home .content-wrapper {
    max-width: 100%;
    padding: 1rem;
    padding-top: 30%;
  }
  #home .wave{
    display: none;
  }
  #home .name {
    font-size: 50px;
  }
  #home .description {
    font-size: 1rem;
    padding: 0 1rem;
  }
  #home .hey-section {
    flex-direction: column;
  }
}